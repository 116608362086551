import '../FruitsPage/style.css'

//importando useSelector do react-redux
import { useSelector } from 'react-redux'

const FruitsPage = () => {
  //acessando o estado global e pegando o state fruits
  const fruits = useSelector(state => state.fruits)

  return (
    <div className="container">
      <div className="box-mg">
        <p>{fruits[0]}</p>
        <img src="https://i.pinimg.com/originals/b0/75/a9/b075a91a887a0144d34f3b39f32ef8ff.png"></img>
        <div className="link">
          <a
            href="https://anamariabraga.globo.com/receita/bolo-de-banana-0004/"
            target="_blank"
            rel="noreferrer"
          >
            Receita - Bolo de Banana
          </a>
        </div>
      </div>

      <div className="box-mg">
        <p>{fruits[1]}</p>
        <img
          width="50px"
          src="https://images.vexels.com/media/users/3/145460/isolated/preview/d08a1157100d2e42f31b4a752e71c33b-ilustracao-da-maca.png"
        ></img>
        <div className="link_maca">
          <a
            href="https://www.tudogostoso.com.br/receita/121412-torta-de-maca.html"
            target="_blank"
            rel="noreferrer"
          >
            Receita - Torta de Maça
          </a>
        </div>
      </div>

      <div className="box-mg">
        <p>{fruits[2]}</p>
        <img
          width="50px"
          src="https://imagensemoldes.com.br/wp-content/uploads/2018/07/Imagem-de-Frutas-Morango-2-PNG-223x300.png"
        ></img>
        <div className="link_morango">
          <a
            href="https://www.tudogostoso.com.br/receita/55504-mousse-de-morango-by-o-nanica.html"
            target="_blank"
            rel="noreferrer"
          >
            Receita - Mouse de Morango
          </a>
        </div>
      </div>
    </div>
  )
}

export default FruitsPage
