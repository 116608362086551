import { legacy_createStore as createStore, combineReducers } from 'redux'

//importando o reducer
import fruitsReducer from '../modules/fruits/reducer'

//combinando os reducers

const reducers = combineReducers({ fruits: fruitsReducer })

const store = createStore(reducers)

export default store
