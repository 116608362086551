const initialState = ['Banana', 'Maça', 'Morango']

const fruitsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default fruitsReducer
